import React from 'react';
import { useTranslation } from 'react-i18next';

import './summary.css';

import SummaryList from '../SummaryList';
import NoDataToShow from '../../../ui/ui-feedback/NoDataToShow/NoDataToShow';
import { SummaryData } from '../../../../store/features/recordings/types';

interface SummaryProps {
  summaryData: SummaryData | string[];
}

function Summary({ summaryData }: SummaryProps) {
  const { t } = useTranslation();

  function isSummaryData(data: SummaryData | string[]): data is SummaryData {
    return (data as SummaryData).data !== undefined;
  }

  let summaryArray: string[] = [];

  if (isSummaryData(summaryData)) {
    summaryArray = summaryData.formatSummary();
  } else if (Array.isArray(summaryData)) {
    summaryArray = summaryData;
  }

  return (
    <>
      <div className="summary__title">{t('summary')}</div>
      {summaryArray.length > 0 ? (
        <SummaryList summaries={summaryArray} />
      ) : (
        <NoDataToShow />
      )}
    </>
  );
}

export default Summary;
