import axiosInstance from '../../../utils/axiosInstance';
import { Partner } from './data';

// eslint-disable-next-line import/no-cycle
import { createPartnerFromData } from './partnerSlice';

const getPartner = async (partnerId: string): Promise<Partner | null> => {
  const url = `partner/${partnerId}`;
  const response = await axiosInstance.get(url);
  const { partner } = response.data;

  return partner ? createPartnerFromData(partner) : null;
};

export default getPartner;
