import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  Navigate,
  BrowserRouter,
} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { Provider, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './index.css';
import './i18nConfig';
import * as Sentry from '@sentry/react';
import PreloadWrapper from './containers/Preload';
import YourAssistant from './containers/YourAssistant';
import { RootState, store } from './store/store';
import Login from './containers/Login';
import { authorized } from './store/features/user/userSlice';
import useTheme from './store/features/partner/hooks/useTheme';
import usePartnerIcon from './store/features/partner/hooks/usePartnerIcon';
import useRedirectPartner from './store/features/partner/hooks/useRedirectPartner';
import usePartnerLoginToken from './store/features/user/hooks/usePartnerLoginToken';

Sentry.init({
  dsn: 'https://cf09702784eba31cd29890957327da83@o4508331072487424.ingest.de.sentry.io/4508332477907024',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/api.(meetric|acceptstack|acceptflow).com/],

  // Session Replay
  // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a
  // lower rate in production.
  replaysSessionSampleRate: 0.1,

  // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions
  // where errors occur.
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const loader = document.getElementById('initial-loader');
loader?.remove();

interface ProtectedRouteProps {
  children: React.ReactElement;
}

interface RedirectIfAuthorizedRouteProps {
  children: React.ReactElement;
}

function ProtectedRoute({ children }: ProtectedRouteProps) {
  if (!authorized()) {
    return <Navigate to="/login" replace />;
  }

  return children;
}

function RedirectIfAuthorized({ children }: RedirectIfAuthorizedRouteProps) {
  if (authorized()) {
    return <Navigate to="/" replace />;
  }

  return children;
}

function App() {
  const { i18n, t } = useTranslation();
  const partner = useSelector((state: RootState) => state.partner.data);
  useTheme(partner?.theme);
  usePartnerIcon(partner?.logoFavicon);
  usePartnerLoginToken();

  useEffect(() => {
    document.title = t('app_name');
  }, [i18n.language]);

  return (
    <Routes>
      <Route
        path="/login"
        element={(
          <RedirectIfAuthorized>
            <Login />
          </RedirectIfAuthorized>
        )}
      />
      <Route
        path="/"
        element={(
          <ProtectedRoute>
            <YourAssistant />
          </ProtectedRoute>
        )}
      />
      <Route path="/logout" element={<Navigate to="/login" replace />} />
    </Routes>
  );
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <PreloadWrapper>
          <App />
        </PreloadWrapper>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);
