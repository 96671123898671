import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Partner, PartnerData, PartnerState } from './data';
// eslint-disable-next-line import/no-cycle
import getPartner from './partnerApi';
import { Status } from '../../../utils/constants';

const initialState: PartnerState = {
  data: null,
  status: Status.Idle,
};

export const loadPartner = createAsyncThunk(
  'partner/loadPartner',
  async ({ id = null }: { id?: string | null } = {}) => {
    const partnerID = id ?? process.env.REACT_APP_PARTNER_ID;
    if (typeof partnerID === 'undefined') return null;

    const response = await getPartner(partnerID);
    return response;
  },
);

export const createPartnerFromData = (data: PartnerData): Partner => ({
  id: data.id,
  name: data.name,
  theme: data.theme,
  icon: data.icon,
  domainSwitchboard: data.domain_switchboard,
  domainDashboard: data.domain_dashboard,
  logoMain: data.logo_main,
  logoFavicon: data.logo_favicon,
  createdAt: data.created_at,
});

export const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    updatePartner: (partnerState, action) => {
      partnerState.data = action.payload;
      partnerState.status = Status.Succeeded;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadPartner.pending, (partnerState) => {
        partnerState.status = Status.Loading;
      })
      .addCase(loadPartner.fulfilled, (partnerState, action) => {
        partnerState.status = Status.Succeeded;
        partnerState.data = action.payload;
      })
      .addCase(loadPartner.rejected, (partnerState) => {
        partnerState.status = Status.Failed;
      });
  },
});

export const { updatePartner } = partnerSlice.actions;

export default partnerSlice.reducer;
