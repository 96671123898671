import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useTheme from '../../store/features/partner/hooks/useTheme';
import usePartnerIcon from '../../store/features/partner/hooks/usePartnerIcon';
import { AppDispatch, RootState } from '../../store/store';
import { loadPartner } from '../../store/features/partner/partnerSlice';
import useRedirectPartner from '../../store/features/partner/hooks/useRedirectPartner';
import usePartnerLoginToken from '../../store/features/user/hooks/usePartnerLoginToken';

interface PreloadProps {
  children: React.ReactNode;
}

function PreloadWrapper({ children }: PreloadProps) {
  const [isReady, setIsReady] = useState(false);
  const partner = useSelector((state: RootState) => state.partner.data);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(loadPartner()).then(() => {
      setIsReady(true);
    });
  }, [dispatch]);

  useTheme(partner?.theme);
  usePartnerIcon(partner?.logoFavicon);
  usePartnerLoginToken();

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return isReady ? <>{children}</> : null;
}

export default PreloadWrapper;
