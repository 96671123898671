import { useEffect } from 'react';

import {
  destroyAuthCookie,
  getAuthCookie,
  setAuthCookie,
} from '../../../../utils/cookieService';

const usePartnerLoginToken = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const partnerToken = urlParams.get('partner_login');

    if (!partnerToken) {
      return;
    }

    const existingToken = getAuthCookie();

    if (partnerToken) {
      if (existingToken) {
        destroyAuthCookie();
      }

      setAuthCookie(partnerToken);
      window.location.replace('/');
    }
  }, []);
};

export default usePartnerLoginToken;
