import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Conversation from '../Conversation';
import Todo from '../Todo';
import Appointment from '../Appointment';
import { Status } from '../../utils/constants';
import { AppDispatch, RootState } from '../../store/store';
import YourAssistantComponent from '../../components/your-assistant/YourAssistant';
import Chat from '../../components/ui/icons/Chat/Chat';
import TodoIcon from '../../components/ui/icons/Todo/Todo';
import Calendar from '../../components/ui/icons/Calendar/Calendar';
import { fetchUser } from '../../store/features/user/userSlice';
import LoadingComponent from '../../components/ui/ui-feedback/LoadingComponent';
import NoDataToShow from '../../components/ui/ui-feedback/NoDataToShow/NoDataToShow';

function YourAssistant() {
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user.data);
  const status = useSelector((state: RootState) => state.user.status);
  const partner = useSelector((state: RootState) => state.partner.data);

  const isLoading = status === Status.Loading || status === Status.Idle;
  const isFailed = status === Status.Failed || !user;
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  if (isLoading) return <LoadingComponent />;

  if (isFailed) return <NoDataToShow text={t('login_error')} />;

  return (
    <YourAssistantComponent
      tabs={[
        {
          label: t('conversation'),
          icon: <Chat />,
          content: <Conversation user={user} searchTerm={searchTerm} />,
        },
        {
          label: t('todos'),
          icon: <TodoIcon />,
          content: <Todo searchTerm={searchTerm} />,
        },
        {
          label: t('appointment'),
          icon: <Calendar width="37px" height="37px" />,
          content: <Appointment user={user} searchTerm={searchTerm} />,
        },
      ]}
      searchTerm={searchTerm}
      handleSearchByTerm={handleSearch}
      partnerIcon={partner?.logoMain ?? partner?.logoFavicon}
    />
  );
}

export default YourAssistant;
