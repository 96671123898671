import { isLocalhostHost } from './axiosInstance';

export function extractDomainFromHostname(hostname: string) {
  const parts = hostname.split('.');

  if (parts.length < 2) return hostname;

  return `.${parts.slice(-2).join('.')}`;
}

export const cookies = {
  get: (key: string): string | null => {
    const cookieEntries = document.cookie
      .split(';')
      .map((c) => c.trim().split('=') as [string, string]);

    const parsed = Object.fromEntries(cookieEntries);

    return parsed[key] ? decodeURIComponent(parsed[key]) : null;
  },
  set: (
    key: string,
    value: string,
    options: Record<string, string | number | boolean> = {},
  ): void => {
    const optionString = Object.entries(options)
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      .map(([optionKey, optionValue]) => `${toKebabCase(optionKey)}=${encodeURIComponent(String(optionValue))}`)
      .join('; ');

    document.cookie = `${key}=${encodeURIComponent(value)}${
      optionString ? `; ${optionString}` : ''
    }`;
  },
  remove: (key: string, options: Record<string, string | number | boolean> = {}) => {
    cookies.set(key, '', { ...options, maxAge: 0 });
  },
};

export function getAuthCookie() {
  return cookies.get('JWT_Token');
}

export function setAuthCookie(token: string) {
  // document.cookie = `JWT_Token=JWT ${token};`;
  cookies.set('JWT_Token', `JWT ${token}`, {
    path: '/',
    domain: isLocalhostHost() ? 'localhost' : extractDomainFromHostname(window.location.hostname),
    secure: true,
    sameSite: 'Strict',
  });
}

export function destroyAuthCookie() {
  cookies.remove('JWT_Token');
}

export const toKebabCase = (str: string): string => str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
