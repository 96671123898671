import { RecordingConversations } from './models/Recording';
import { formatSummaryDefault, formatSummaryWithAsterisks } from './util/utilService';

import { Status } from '../../../utils/constants';

export interface RecordingsState {
  data: RecordingConversations;
  status: Status;
  transcriptStatus: Status;
  urlStatus: Status;
  todoStatus: Status;
  appointmentStatus: Status;
}

export class SummaryData {
  data: {
    summary: {
      session_id: string;
      summary: string;
      summary_dashboard: string | null;
      summary_switchboard: string | null;
      seller_questions: string[];
      buyer_questions: string[];
      objections: string[];
      deal_probability_reason: string;
      deal_probability_percent: number | null;
    }
  };

  constructor(
    session_id: string,
    summary: string,
    seller_questions: string[],
    buyer_questions: string[],
    objections: string[],
    deal_probability_reason: string,
    deal_probability_percent: number | null,
    summary_switchboard: string | null,
    summary_dashboard: string | null,
  ) {
    this.data = {
      summary: {
        session_id,
        summary,
        seller_questions,
        buyer_questions,
        objections,
        deal_probability_reason,
        deal_probability_percent,
        summary_dashboard,
        summary_switchboard,
      },
    };
  }

  formatSummary = () => {
    const summary = this.data.summary.summary_switchboard !== null
    && this.data.summary.summary_switchboard !== undefined
      ? this.data.summary.summary_switchboard
      : '';

    if (/\*\*/.test(summary)) {
      return formatSummaryWithAsterisks(summary);
    }

    // Call the static method
    return formatSummaryDefault(summary);
  };
}
