export const formatSummaryDefault = (summary: string) => {
  const formattedSummary = summary.replace(
    /^(.*?)(: |: |- )/gm,
    '<span style="font-weight: bold">$1</span>$2',
  );

  // Split the summary into points based on newlines
  return formattedSummary.split(/\n+/gm).filter(Boolean);
};

export const formatSummaryWithAsterisks = (summary: string) => {
  // Split the summary into lines
  const lines = summary.split('\n');
  const processedLines: string[] = [];

  lines.forEach((line: string) => {
    let newLine = line;
    // Count pairs of asterisks (**)
    const asteriskPairCount = (line.match(/\*\*/g) || []).length;

    // If the count of pairs is odd, wrap the line with additional asterisks
    if (asteriskPairCount % 2 !== 0) {
      newLine = newLine.replace(/\*/g, ''); // Remove all asterisks in the line
      newLine = `**${newLine}**`; // Add a pair of asterisks at the start and end
    }

    // Replace markdown bold notation with <span>
    newLine = newLine.replace(
      /\*\*(.*?)\*\*/g,
      '<span style="font-weight: bold">$1</span>',
    );

    // Push the processed line to the results array
    processedLines.push(newLine);
  });

  // Join the processed lines back into a single formatted string
  return processedLines.filter(Boolean);
};
